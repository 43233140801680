.share-video {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    padding: 3px 10px;
    overflow: hidden;
}

.enabled .share-video {
    cursor: pointer;
}

.share-video-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    opacity: 1;
    transition: all .2s linear;
}

.disabled .share-video-btn {
    cursor: auto;
}

.share-video-btn svg {
    font-size: 17px;
}

.share-social-media-cont {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    transform: translateX(100%);
    opacity: 0;
    transition: all .1s linear;
}

.share-social-media-cont svg {
    font-size: 18px;
    cursor: pointer;
}

.share-modal {
    position: relative;
}

.share-video-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.share-video-title {
    font-size: 15px;
    margin-bottom: 12px;
    padding: 2px 0;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
    font-weight: bold;
}

.share-video-and-credentials {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    gap: 25px;
}

.share-video-form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
}

.select-credential-info {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    color: var(--grey);
}

.share-video-review {
    width: 50%;
    height: 100%;
}

.share-video-review video {
    width: 100%;
    max-height: 100%;
    flex-grow: 1;
}

.credentials-and-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

input.share-video-title-input {
    font-size: 14px !important;
}

.share-original-title {
    margin-top: 7px;
    gap: 5px;
    color: var(--grey);
}

.original-video-desc {
    font-style: italic;
}

.use-original-title {
    color: white;
    cursor: pointer;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left: 7px;
    font-size: 17px;
}

.share-credentials-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* border: 1px solid purple; */
}

.user-logged-in {
    display: flex;
    flex-direction: column;
    min-height: 110px;
    width: 100%;
    /* border: 1px solid red; */
}

.logged-in-title {
    font-size: 17px;
    padding-bottom: 7px;
    padding-left: 2px;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
}

.share-user-profile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 2px;
    padding-top: 10px;
    overflow: hidden;
}

.user-profile-and-name {
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 10px;
}

.user-profile-and-name img {
    height: 37px;
}

.user-profile-and-name div {
    flex-grow: 1;
}

.fb-login-error {
    margin: 12px 0;
    color: var(--warning-text-red);
}

.fb-login-error div {
    flex-grow: 1;
}

.instagram-credentials {
    position: relative;
    width: 100%;
}

.credentials-details {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    padding-left: 2px;
    height: 40px;
}

.in-list .credentials-details {
    padding-top: 0;
}

.credentials-details-single.active {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.credentials-details-single.in-list {
    margin-top: 0;
    padding: 7px;
    cursor: pointer;
}

.credentials-details-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    width: 37px;
    border: 1px solid var(--grey);
    background-color: lightgrey;
}

.credentials-details-img img {
    width: 32px;
}

.credentials-details-img svg {
    font-size: 35px;
    color: white;
}

.credentials-details-username-id {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 3px;
    font-size: 12px;
    color: var(--grey);
}

.active .credentials-details-username-id {
    color: var(--dark-blue);
}

.credentials-details-username,
.credentials-details-id {
    display: flex;
    align-items: center;
}

.credentials-details-username div,
.credentials-details-id div {
    width: 80px;
}

.credentials-details-username span,
.credentials-details-id span {
    margin-right: 5px;
}

.credentials-expired {
    color: var(--warning-text-red);
    margin-top: 7px;
    font-size: 11px;
    font-weight: 600;
}

.no-credentials-found {
    color: rgba(255, 255, 255, .4);
    font-style: italic;
    padding: 12px 0;
    margin-bottom: 5px;
}

button.update-credentials {
    padding: 3px 7px;
    font-size: 11px;
    margin-right: 3px;
}

.active button.update-credentials {
    color: var(--dark-blue);
    border-color: var(--dark-blue);
}

button.login-or-create-credentials-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 7px 5px;
    cursor: pointer;
    border: none;
    font-size: 14px;
    background-color: #1877F2;
}

button.login-or-create-credentials-btn svg {
    font-size: 20px;
    margin-top: -1px;
}

.login-to-select-credentials {
    color: rgba(255, 255, 255, .4);
    padding: 7px;
}

button.share-to-social-media-btn {
    float: right;
    width: fit-content;
}

.confirm-logout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
    background-color: black;
    border-radius: 3px;
    transform: translateX(100%);
    opacity: 0;
    transition: all .1s linear;
    cursor: auto;
}

.confirm-logout.show {
    transform: translateX(0%);
    opacity: 1;
}

.create-delete-credentials-title {
    flex-grow: 1;
}

.delete-credentials-btn {
    display: flex;
    gap: 7px;
}

.checking-credentials-text {
    color: var(--grey);
}

.checking-credentials-loading {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.credential-zero-quotas {
    color: var(--warning-text-red);
    margin-top: 10px;
    font-size: 11px;
    font-weight: 600;
}

.failed-to-fetch-user {
    color: var(--grey);
    font-style: italic;
}

.loading-credential {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.credential-error {
    margin-top: 7px;
    color: var(--warning-text-red);
    font-weight: 600;
}

.login-for-credentials {
    margin-top: 7px;
    color: var(--grey);
    font-style: italic;
}

.share-testing-btn {
    display: flex;
    gap: 5px;
    margin-top: 20px;
}

.login-with-google-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #Db4437;
    border-color: #Db4437;
}

.login-with-google-btn svg {
    font-size: 16px;
}

/* HOVER */
.share-social-media-cont svg:hover,
.list-of-credentials-btn:hover {
    color: var(--dark-yellow);
}
.enabled .share-video:hover .share-social-media-cont {
    transform: translateX(0%);
    opacity: 1;
}
.enabled .share-video:hover .share-video-btn {
    opacity: 0;
}
.use-original-title:hover {
    color: var(--grey);
}
button:not([disabled]).login-or-create-credentials-btn.fb:hover {
    background-color: #1877F2;
    color: white;
    filter: brightness(85%);
}
.login-or-create-credentials-btn.in-list:hover {
    color: var(--dark-yellow);
    background: transparent;
}
.active button.update-credentials:hover {
    color: white;
    border-color: white;
}
button.login-with-google-btn:hover {
    background-color: #Db4437;
    border-color: #Db4437;
    color: white;
    filter: brightness(80%);
}