.thumbnail-img {
    width: 178px;
    height: 100px;
    margin: 5px;
    cursor: pointer;
}

.thumbnail-modal-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.thumbnail-img-cont {
    text-align: center;
    max-height: 550px;
    overflow: auto;
}

.edit-thumbnail-cont {
    display: flex;
    flex-direction: column;
    width: 245px;
}

.metadata-thumbnail-img {
    width: 100%;
    cursor: pointer;
}

.thumbnail-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 177px;
    padding: 7px;
    background: black;
    border: 3px solid rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    cursor: pointer;
}

.select-thumbnail-cont {
    width: 100%;
}

.playlist-thumbnail-cont > .select-thumbnail-cont > .select-thumbnail-opt {
    background: transparent;
}

.select-thumbnail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    width: 100%;
}

.select-thumbnail-title {
    margin-right: 7px;
}

.cancel-select-thumbnail {
    text-align: center;
    margin-top: 12px;
}

.select-thumbnail-btn button {
    margin: 2px 0;
    width: 100%;
}

.choose-thumbnail {
    margin-top: 2px;
    width: 100%;
}

.selected {
    outline: 3px solid var(--light-blue);
}

/* UPLOAD THUMBNAIL */
.upload-thumbnail-cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 425px;
}

.upload-thumbnail-dnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 425px;
    margin-bottom: 5px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
}

.upload-thumbnail-dnd p{
    margin: 5px;
}

.image-upload-icon {
    height: 175px;
    font-size: 125px;
    color: rgba(255, 255, 255, 0.15);
}

.image-input-label {
    padding: 5px 9px;
    margin-top: 9px;
    background: var(--dark-yellow);
    color: var(--dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.uploaded-thumbnail-img {
    height: 175px;
    max-width: 275px;
    padding: 21px 0;
}

.image-upload-input {
    display: none;
}

.is-dropping > * {
    pointer-events: none;
}

.is-dropping .image-upload-icon,
.is-dropping .max-image-size-info {
    border-color: var(--light-blue);
    color: var(--light-blue);
}

.is-dropping > .image-input-label {
    background: var(--light-blue);
}

.max-image-size-info {
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.5);
}

/* HOVER */
.image-input-label:hover {
    background: var(--light-blue);
}

/* MEDIA QUERY */
@media screen and (max-width: 480px) {
    .edit-thumbnail-cont {
        width: 100%;
    }
}