.action-menu,
.action-dropdown,
.action-title, 
.action-clear,
.video-action-modal,
.action-options-cont,
.selected-video-single,
.action-modal-title,
.action-msg,
.completed-action,
.completed-msg,
.action-select-all {
    display: flex;
}

.action-dropdown,
.action-title, 
.action-clear,
.video-action-modal,
.selected-video-single,
.action-msg,
.completed-action,
.completed-msg,
.action-select-all {
    align-items: center;
}

.action-menu {
    justify-content: space-between;
    height: 27px;
    margin: 7px 0;
    font-size: 1.2em;
}

.action-dropdown {
    justify-content: flex-start;
    width: 250px;
    font-size: 1.2em; 
}

.dropdown-cont {
    position: relative;
    width: 160px;
}

.action-title, 
.action-clear {
    justify-content: center;
    width: 160px;
    height: 27px;
    border: 1px solid rgba(255,255,255,0.25);
    font-size: .7em;
    cursor: pointer;
}

.action-title p{
    width: 90%;
}

.action-options-cont {
    position: absolute;
    width: 100%;
    flex-direction: column;
    background: black;
    z-index: 1;
}

.action-options-cont button {
    border: none;
    min-height: 36px;
    font-size: .7em;
    /* color: white; */
    cursor: pointer;
}

.action-options-cont button:focus {
    background: black;
}

.action-clear, 
.action-clear-none {
    padding: 0;
    width: 170px;
    font-size: .8em;
}

.action-none-selected {
    color: rgba(255,255,255,0.35);
}

.video-action-modal {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.selected-video-cont {
    width: 95%;
    height: 80%;
    margin: 15px 0;
    padding: 0 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    overflow-y: auto;
}

.selected-video-single {
    width: 100%;
    height: 65px;
    justify-content: space-between;
    padding: 7px 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.action-modal-title {
    justify-content: center;
    width: 100%;
    font-size: 1.5em;
}

.selected-thumbnail {
    height: 100%;
}

.selected-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 90%;
    margin-left: 12px;
    margin-right: 5px;
}

.selected-duration-desc {
    display: flex;
    align-items: center;
    height: 100%;
}

.selected-duration {
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid rgb(100, 100, 100);
}

.selected-warning {
    height: 30%;
    color:rgb(150, 150, 150)
}

.direct-download {
    width: 200px;
    font-size: 12px;
}

.download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 175px;
    padding: 0;
    margin-right: 5px;
    font-size: 1em;
}

.downloaded-feedback {
    color: var(--button-green);
    font-weight: bold;
}

.action-msg,
.failed-msg {
    justify-content: flex-end;
    width: 140px;
    margin-left: 10px;
    color: var(--button-green);
    font-weight: bold;
}

.failed-msg {
    color: var(--warning-text-red);
}

.selected-delete {
    width: 5%;
    padding: 9px;
    font-size: 35px;
    cursor: pointer;
}

.completed-action {
    position: absolute;
    justify-content: center;
    height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: #161f35ce;
    font-size: 1.2em;
}

.completed-msg {
    flex-direction: column;
    justify-content: center;
    height: 150px;
    width: 450px;
    background-color: var(--dark-blue);
    border: 1px solid white;
    border-radius: 5px;
}

.action-select-all {
    width: 100px;
    padding: 5px 0;
    font-size: 13px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 2px;
    cursor: pointer;
}

.select-all,
.unselect-all {
    margin: 0 7px;
    font-size: 15px;
}

.unselect-all {
    color: var(--dark-yellow);
}

.bottom-list {
    height: 5px;
}

/* HOVER */
.action-options-cont button:hover {
    background: var(--light-blue);
    color: var(--dark-blue);
    cursor: pointer;
}
.selected-delete:hover {
    color: var(--dark-yellow);
}
.action-select-all:hover {
    background: var(--light-blue);
}

@media screen and (max-width: 768px) {
    .action-menu {
        gap: 5px;
    }
    .action-dropdown {
        width: 50%;
        padding: 0;
    }
    .action-clear, 
    .action-clear-none {
        width: 50%;
    }
    .dropdown-cont,
    .action-title {
        width: 100%;
    }
}