.event-listing {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}
.event-filters {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    gap: 10px 5px;
    padding-bottom: 10px;
}
.event-filters > div {
    display: flex;
}
.inline-shirt {
    display: inline-block;
    line-height: 0.8em;
    min-width: 1.4em;
    height: 1em;
    border-radius: 10%;
    border: 1px solid rgba(255,255,255,0.35);
    text-align: center;
}
.filter-controls {
    position: absolute;
    right: 0;
    top: 0;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    font-size: 12px;
}
.filter-controls > button {
    font-size: 1em;
    padding: 0.26em 0.4em;
}

@media screen and (max-width: 768px) {
    .event-filters {
        display: none;
    }
}