.user-group-info-cont {
    padding-left: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.user-group-name {
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 15px;
    gap: 12px;
}

.user-group-logo-icon {
    font-size: 42px;
}

.group-users-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 25px;
}

.group-users-title {
    color: var(--grey);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 12px;
}

.group-user-cont {
    flex-grow: 1;
    width: 100%;
}

.group-user-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--grey);
}

.group-user-single {
    height: 50px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: calc(50% - 10px);
    max-width: 300px;
    background-color: rgba(0,0,0,.25);
    border: 1px solid rgba(255, 255, 255, 0.15);
    /* box-shadow: 0px 1px 8px grey; */
    margin-bottom: 10px;
    margin-right: 10px;
    padding-right: 10px;
    border-radius: 2px;
}

.group-user-name {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 7px;
    margin-right: 20px;
    border-radius: 2px;
}

.group-user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--dark-blue);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 100%;
    min-width: 38px;
}

.group-username {
    word-break: break-all;
}

.group-user-icon svg {
    color: var(--dark-blue);
    font-size: 25px;
}

.group-user-remove {
    font-size: 14px;
    margin-top: 3px;
    cursor: pointer;
}

.add-user-cont {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.add-user-search {
    margin-bottom: 7px;
    display: flex;
}

.add-user-search input[type="text"] {
    min-width: 200px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background-color: rgba(0,0,0,0.25);
    padding: 0 7px;
    margin-right: 10px;
    font-size: 14px;
    flex-grow: 1;
}

.add-user-search input[type="text"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.add-user-result-name {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    gap: 10px;
}

.add-user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--dark-blue);
    font-size: 25px;
    /* border-radius: 50%; */
}

.add-user-check {
    font-size: 15px;
    color: var(--button-green);
}

.add-user-checkbox,
.add-user-uncheckbox {
    margin-left: 24px;
    font-size: 17px;
}
.add-user-checkbox {
    color: var(--button-green);
}

.add-user-result-name button{
    font-size: 14px;
}

.user-in-list {
    display: none;
    color: var(--button-green);
    font-weight: 600;
}

.user-in-list.visible {
    display: flex;
}

.group-no-user {
    color: var(--grey);
    margin-bottom: 10px;
    font-style: italic;
}

.allowed-events-list {
    width: 100%;
    margin-top: 25px;
}

.allowed-event-single {
    display: flex;
    align-items: center;
    gap: 10px;
    float: left;
    font-size: 15px;
    height: 37px;
    width: calc(33.3% - 10px);
    max-width: 225px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 251, 0, 0.5);
    background-color: rgba(0,0,0,0.25);
    padding-right: 10px;
    border-radius: 2px;
    font-size: 13px;
}

.allowed-event-single-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 100%;
    min-width: 35px;
}

.allowed-event-single-icon svg {
    font-size: 22px;
    margin-bottom: -2px;
}

.allowed-event-single-icon.yellow-card svg {
    color: yellow !important;
}

.manage-allowed-events-btn {
    margin-top: 25px;
}

.page-no-permission {
    margin: 0 auto;
    margin-top: 25px;
    color: var(--grey);
    font-style: italic;
}

.no-allowed-events {
    font-size: 12px;
    font-style: italic;
    color: var(--grey);
}

/* MANAGE ALLOWED EVENTS */
.allowed-tags-cont {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.available-allowed-tags-cont {
    flex-grow: 1;
    margin: 12px 0;
    margin-right: -10px;
    overflow-y: auto;
}

.available-tag-single {
    display: flex;
    align-items: center;
    gap: 10px;
    float: left;
    font-size: 15px;
    height: 37px;
    width: calc(33.3% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding-right: 10px;
    border-radius: 2px;
    font-size: 13px;
}

.available-tag-single.allowed {
    color: white;
    border-color: var(--dark-yellow);
    background-color: rgba(0,0,0,0.1);
}

.available-tag-single-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    color: var(--dark-blue);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 100%;
    width: 35px;
}

.allowed .available-tag-single-icon {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
}

.allowed .available-tag-single-icon.yellow-card svg {
    color: yellow !important;
}

.available-tag-single-icon svg {
    font-size: 24px;
    margin-bottom: -2px;
}

/* HOVER */
.group-user-remove:hover {
    color: var(--dark-yellow);
}
.available-tag-single:not(.allowed):hover {
    border-color: white;
}

@media screen and (max-width: 768px) {
    .group-user-single {
        max-width: unset;
    }
    .available-tag-single {
        width: calc(50% - 10px);
        font-size: 12px;
        gap: 10px;
        height: 35px;
    }
    .allowed-event-single {
        font-size: 15px;
        height: 35px;
        width: calc(50% - 10px);
        max-width: unset;
        padding-right: 10px;
        font-size: 12px;
    }
    .allowed-event-single-icon {
        min-width: 33px;
    }
    .allowed-event-single-icon svg {
        font-size: 20px;
    }
}

@media screen and (max-width: 575px) {
    .group-user-single {
        width: calc(100% - 10px);
    }
    .available-tag-single {
        width: calc(100% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .allowed-event-single {
        font-size: 15px;
        height: 35px;
        width: calc(100% - 10px);
        max-width: unset;
        padding-right: 10px;
        font-size: 12px;
    }
    .allowed-event-single-icon {
        min-width: 33px;
    }
    .allowed-event-single-icon svg {
        font-size: 20px;
    }
}