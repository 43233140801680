.fullscreen-filters-cont {
    position: absolute;
    left: 0;
    height: 100%;
    width: 0;
    padding: 15px 0;
    background-color: #101525;
    z-index: 1;
    transition: width .1s linear;
    overflow: hidden;
}

.fullscreen-filters-cont.view {
    width: 100%;
}

.fullscreen-title-and-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 25px;
}

.fullscreen-filter-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
}

.fullscreen-filter-close {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    font-size: 14px;
}

.fullscreen-filter-close svg {
    font-size: 16px;
    margin-bottom: 1px;
}

.all-filters-cont {
    padding: 0 10px;
    margin-bottom: 25px;
}

button.clear-filter-btn {
    margin: 0 10px;
    width: calc(100% - 20px);
    border: 1px solid rgba(255, 255, 255, 0.25);
}

button.clear-filter-btn:hover {
    background-color: transparent;
    color: white;
    border-color: rgba(255, 255, 255, 0.25);
}