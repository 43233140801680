.exports-and-shares {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.exports-and-shares-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.exports-table-filter,
.shares-table-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    cursor: pointer;
}

.exports-table-filter.active,
.shares-table-filter.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-weight: 500;
}

.exports-and-shares-filter div:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.exports-cont {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.exports-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: calc(100% - 125px);
    margin-bottom: 15px;
}

.exports-table div {
    text-align: center;
}

.export-clip-single {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.export-clip-single.expired {
    color: rgba(255, 255, 255, 0.5);
}

.export-submitted {
    width: 10%;
}

.export-source {
    width: 25%;
}

.export-preset {
    width: 7%;
}

.export-creator {
    width: 15%;
}

.export-expires {
    width: 12%;
}

.export-size {
    width: 8%;
}

.export-duration {
    width: 8%;
}

.export-share {
    width: 8%;
}

.export-download {
    width: 8%;
}

.export-delete {
    width: 2%;
}

.export-delete.icon {
    color: white;
}

.export-processing {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 44%;
}

.export-source.data {
    font-weight: 600;
    padding: 0 5px;
}

.export-preset.data {
    max-width: 7%;
    padding: 0 5px;
    word-wrap: break-word;
}

.export-size.data,
.export-duration.data {
    color: var(--light-blue);
}

.export-clip-single:not(.expired) .export-size.data,
.export-clip-single:not(.expired) .export-duration.data {
    font-weight: 700;
}

.remove-export-btn {
    font-size: 13px;
    cursor: pointer;
}

.export-msg-cont {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--button-green);
}

.export-processing-time-info {
    color: rgba(255, 255, 255, .7);
}

.exports-table-info {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    gap: 3px;
    width: 45%;
    color: rgba(255, 255, 255, .7);
    margin-bottom: 15px;
    font-size: 12px;
}

.download-expired {
    color: var(--warning-text-red);
    font-weight: 500;
}

.exports-table-info svg {
    min-width: 14px;
    font-size: 14px;
}

.no-exports-table {
    text-align: center;
    color: rgba(255, 255, 255, .7);
    margin-top: 25px;
}

/* MOBILE */
.exports-mobile-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: calc(100% - 70px);
    margin-bottom: 15px;
    overflow: auto;
}

.export-single-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #101525;
    cursor: pointer;
}

.export-mobile-source {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.export-mobile-flex-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid green; */
}

.export-mobile-flex-between.center {
    align-items: center;
}

.export-mobile-flex {
    display: flex;
    align-items: center;
    gap: 20px;
}

.export-mobile-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.export-mobile-info.size {
    width: 75px;
}

.export-mobile-info-title {
    font-size: 10px;
    font-weight: 500;
    color: var(--grey);
    text-transform: uppercase;
}

.export-single-mobile.expired,
.expired .export-mobile-info-title {
    color: rgba(255, 255, 255, 0.5);
}

.export-mobile-info-desc {
    font-size: 13px;
    font-weight: 600;
}

.export-mobile-info-text {
    font-size: 13px;
    font-weight: 500;
}

.export-mobile-info-text.creator {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.export-mobile-more {
    font-size: 20px;
}

.export-mobile-info-size,
.export-mobile-info-duration {
    color: var(--light-blue);
    font-size: 14px;
    font-weight: 600;
}

.expired .export-mobile-info-size,
.expired .export-mobile-info-duration {
    font-weight: unset;
}

.export-mobile-download svg {
    color: white;
    font-size: 20px;
    margin-right: 4px;
}

.export-mobile-expired {
    color: var(--warning-text-red);
    font-size: 13px;
    font-weight: 500;
}

.export-mobile-extra {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #101525;
    transform: translateX(-100%);
    transition: transform .2s linear;
    /* border: 1px solid red; */
}

.export-mobile-extra.show {
    transform: translateX(0%);
}

.export-mobile-extra {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.export-mobile-extra .export-mobile-flex {
    width: calc(100% - 30px);
}

.export-mobile-extra .export-mobile-info {
    width: calc(50% - 10px);
}

.export-mobile-download-loading {
    position: relative;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    margin-right: 2px;
    overflow: hidden;
}

.export-mobile-download-loading::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
        var(--light-blue) 90deg,
        transparent 0deg
    );
    animation: rotate 1.5s linear infinite;
}

.export-mobile-download-loading::after {
    content: "";
    position: absolute;
    inset: 3px;
    background: #101525;
    border-radius: 50%;
}

.export-mobile-download-loading div {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    transform: translate(50%, 40%);
}

/* HOVER */
.remove-export-btn:hover {
    color: var(--dark-yellow);
}
.table-header-sort:hover {
    color: white;
}
.exports-table-filter:not(.active):hover,
.shares-table-filter:not(.active):hover {
    filter: brightness(80%);
}

@media screen and (max-width: 768px) {
    .exports-modal-title {
        text-align: center;
        font-size: 16px;
        margin-top: 3px;
        margin-bottom: 12px;
        font-weight: bold;
    }
}