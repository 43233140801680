.game-page-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.game-page-container .game-info {
    min-height: 100px;
    text-align: center;
    font-size: larger;
    width: 100%;
    padding-top: 20px;
}

.game-section-header {
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    margin: 6px 0;
    color: var(--dark-yellow);
    border-bottom: 1px solid var(--dark-yellow);
}

.game-asset-listing {
    min-height: 105px;
    max-height: 135px;
    overflow-y: scroll;
}

.game-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 7px;
}

.score-and-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.game-info-home,
.game-info-away {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 40%;
    height: 100%;
    font-size: 17px;
    font-weight: 500;
}

.game-info-away {
    flex-direction: row-reverse;
}

.team-name {
    text-align: left;
}

.game-info-away .team-name {
    text-align: right;
}

.upload-from-match {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 32px;
    margin: -15px 0;
}

.upload-from-match button {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 7px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

.upload-from-match-icon {
    margin-right: 7px;
}

.club-name-home,
.club-name-away {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    background-color: black;
    color: darkgrey;
    box-sizing: border-box;
}

.club-name-away {
    border-left: 50px solid var(--dark-blue);
    border-right: 0;
    justify-content: flex-end;
}

.club-name-away>.club-name {
    justify-content: right;
    text-align: right;
}

.game-info-date {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-size: 12px;
    padding: 3px 5px;
}

.game-info-score {
    margin: 5px 0;
    font-size: 25px;
    font-weight: bold;
}

.game-info-phase {
    font-size: 12px;
    text-transform: capitalize;
    color: var(--grey);
}

.game-info-logo {
    height: 80px;
}

.game-info-live {
    padding: 2px 7px;
    background-color: #E2051B;
    font-size: 12px;
    border-radius: 2px;
}

.tag-video-btn {
    margin: 0 auto;
    width: fit-content;
}

/* HOVER */
.upload-from-match button:hover {
    background: var(--light-blue);
}

@media screen and (max-width: 480px) {
    .tag-video-btn {
        width: 100%;
        margin: 7px 0;
    }
}

@media screen and (max-width: 768px) {
    .game-asset-listing {
        min-height: 110px;
    }
    .game-page-container .game-info {
        min-height: unset;
        text-align: center;
        font-size: unset;
        padding-top: 0;
    }
    .game-info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .game-info-home,
    .game-info-away {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 30%;
        height: fit-content;
        gap: 5px;
        font-size: 15px;
    }
    .game-info-away {
        flex-direction: column;
        justify-content: unset;
    }
    .game-info-logo {
        height: 60px;
    }
    .game-info-date {
        font-size: 11px;
        padding: 2px 5px;
    }
    .score-and-time {
        flex-grow: 1;
    }
    .game-section-header {
        font-size: 12px;
    }
}

@media screen and (max-width: 480px) {
    .tag-video-btn {
        width: 100%;
        margin: 7px 0;
    }
    .team-name {
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .game-info-home,
    .game-info-away {
        font-size: 14px;
    }
}