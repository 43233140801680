.tagging-main {
    height: fit-content;
    width: 100%;
    padding-bottom: 20px;
}

.tagging-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

.tagging-cont.game-tagging {
    max-width: 768px;
}

.tagging-live-time-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2px;
    font-size: 40px;
    padding: 7px 0;
    margin-bottom: 30px;
    background-color: #101525;
}

.tagging-live-time {
    text-align: center;
    width: 60px;
    /* border: 1px solid red; */
}

.tagging-form {
    width: 100%;
    display: none;
    flex-direction: column;
    gap: 30px;
    height: fit-content;
    padding: 0 3px;
    padding-bottom: 10px;
    /* border: 1px solid green; */
}

.open-form .tagging-form {
    display: flex;
}

.tagging-section-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 2px 0;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
}

.tags-options-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
    /* border: 1px solid yellow; */
}

.open-form .tags-options-cont {
    display: none;
}

.tags-options-cont.no-tags {
    text-align: center;
    padding-top: 25px;
    color: var(--grey);
    font-style: italic;
}

.tagging-tags-options {
    width: 100%;
}

.tag-single {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 40px;
    width: calc(25% - 8px);
    float: left;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.1);
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    padding: 0 7px;
}

.tag-single.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
}

.tag-single svg {
    min-width: 25px;
    font-size: 22px;
    color: white;
}

.tag-name {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-yellow);
}

.tag-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tag-details-single {
    display: flex;
    align-items: center;
}

.tag-details-single.time {
    align-items: flex-start;
    margin: 15px 0;
}

.time .tag-details-title {
    padding-top: 3px;
}

.tag-details-title {
    min-width: 100px;
}

.tagged-time {
    font-size: 18px;
    font-weight: 600;
    color: var(--light-blue);
}

.tagged-time-passed {
    color: var(--grey);
    margin-top: 3px;
    margin-left: 17px;
}

.tagging-time-adjustment {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

.time-adjust-btn {
    float: left;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 70px;
    padding: 0;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 7px;
    font-size: 13px;
    cursor: pointer;
}

.tagging-params-and-options {
    display: flex;
    min-height: 100px;
    width: 100%;
    /* border: 1px solid red; */
}

.tagging-tag-param {
    position: relative;
    width: calc(50% - 10px);
    background-color: var(--dark-blue);
    z-index: 2;
    /* border: 1px solid yellow; */
}

.tag-param-single {
    display: flex;
    align-items: center;
    min-height: 42px;
    width: 100%;
    padding-left: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    cursor: pointer ;
}

.tag-param-single:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.tag-param-single.active,
.tag-param-single.active .tag-param-single-value {
    color: var(--dark-yellow);
}

.tag-param-single-title {
    min-width: 110px;
}

.tag-param-single-value {
    flex-grow: 1;
    text-align: right;
    color: var(--grey);
}

.tag-param-single svg {
    min-width: 20px;
    font-size: 17px;
    margin-top: 2px;
    margin-left: 5px;
}

.tagging-param-options-cont {
    max-height: 350px;
    width: calc(50% - 10px);
    background-color: #101525;
    z-index: 1;
    transform: translateX(calc(-100%));
    transition: all .1s linear;
    overflow: auto;
}

.tagging-param-options-cont.show {
    opacity: 1;
    transform: translateX(calc(0% + 20px));
}

.tagging-param-options {
    width: 100%;
}

.param-option-single {
    display: flex;
    gap: 7px;
    padding: 8px;
    cursor: pointer;
}

.param-option-single.active {
    color: var(--dark-yellow);
}

.team .param-option-single {
    align-items: center;
}

.param-option-team-logo {
    height: 32px;
}

.param-option-player-number {
    display: flex;
    justify-content: center;
    width: 17px;
}

.param-option-player-name {
    display: flex;
    flex-grow: 1;
}

.param-option-player-selected {
    font-style: italic;
    color: var(--grey);
    padding-right: 3px;
    font-size: 12px;
}

.select-team-show-players {
    text-align: center;
    padding-top: 25px;
    color: var(--grey);
    font-style: italic;
}

.select-tag-to-start {
    text-align: center;
    margin: 20px 0;
    color: var(--grey);
    font-style: italic;
}

.cancel-tagging-btn {
    margin: 0 auto;
    margin-top: 12px;
    width: fit-content;
}

/* TAGGED EVENTS */
.tagged-events-cont {
    margin-top: 25px;
}

.tagged-events-title {
    color: var(--dark-yellow);
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid var(--dark-yellow);
    padding-left: 2px;
    padding-bottom: 3px;
    margin-bottom: 10px;
}

/* HOVER */
.tag-single:not(.active):hover {
    border-color: white;
    color: white
}
.tag-param-single:hover,
.tag-param-single:hover .tag-param-single-value {
    color: var(--dark-yellow);
}
.param-option-single:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}
button.time-adjust-btn:not([disabled]):hover {
    border-color: white;
    background-color: unset;
    color: white;
}

.empty-tagging-page {
    margin: 0 auto;
    font-style: italic;
    color: var(--grey);
}

/* MOBILE */
.param-options-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    /* border: 1px solid red; */
}

.param-options-mobile-header-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    padding: 2px 0;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
}

.param-options-mobile-header-back {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.param-options-mobile-header-back svg {
    font-size: 18px;
}

.match-tagging-btn {
    display: flex;
    gap: 15px;
    margin: 0 auto;
    width: fit-content;
    gap: 7px;
    margin-top: 10px;
}

.tagging-video-live {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 12px;
}

.tagging-form-mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--dark-blue);
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .tag-single {
        height: 40px;
        width: calc(50% - 8px);
        margin-right: 8px;
        margin-bottom: 8px;
        font-size: 14px;
    }
    .tag-param-single {
        min-height: 50px;
    }
    .tag-single:not(.active):hover {
        color: rgba(255, 255, 255, 0.75);
        border-color: rgba(255, 255, 255, 0.25);
    }
    .tag-single.fan-tagging {
        width: 100%;
    }
    .tagging-time-adjustment {
        gap: 2px;
    }
    .time-adjust-btn {
        width: calc(20% - 5px);
        margin-right: 5px;
        margin-bottom: 5px;
        height: 32px;
    }
    button.time-adjust-btn:hover {
        border-color: rgba(255, 255, 255, 0.3) !important;
    }
    .tagging-tag-param {
        width: 100%;
    }
    .tagging-param-options-cont {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: unset;
        min-height: 100%;
        height: fit-content;
        width: 100%;
        transform: translate(-100%);
        padding: 10px;
        background-color: #101525;
        z-index: 10;
        transition: all .1s linear;
        /* border: 1px solid red; */
    }
    .tagging-param-options-cont.show {
        transform: translateX(0%);
    }
    .param-option-single {
        padding: 15px 7px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
    .param-option-single:hover {
        background-color: unset;
        color: unset;
    }
    .select-tag-open-form {
        margin-top: 5px;
    }
    .tag-param-single:hover {
        color: unset;
    }
    .tag-param-single:hover .tag-param-single-value {
        color: var(--grey);
    }
    .select-tag-to-start {
        margin: 10px 0;
    }
    .cancel-tagging-btn {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .tag-single {
        /* height: 45px; */
        margin-right: 7px;
        margin-bottom: 7px;
    }
    .tagging-live-time-cont {
        font-size: 40px;
        padding: 7px 0;
        margin-top: 0;
        margin-bottom: 20px;
        background-color: #101525;
    }
    .tagging-live-time {
        width: 50px;
    }
    .time-adjust-btn {
        margin-right: 5px;
        flex-grow: 1;
        cursor: pointer;
    }
    .time-adjust-btn {
        width: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .tag-details-title {
        min-width: 90px;
    }
    .tag-name {
        font-size: 15px;
    }
}