.video-entry-metadata,
.game-time,
.video-entry-metadata > i {
    display: flex;
    align-items: center;
}

.video-entry-thumb,
.video-entry img,
.metadata-and-details,
.video-entry-metadata > i {
    height: 100%;
}

.video-entry-metadata,
.game-time {
    width: 100%;
}

.video-entry-is-unlisted {
    color: var(--dark-yellow);
    margin-left: 5px;
}

.video-entry {
    position: relative;
    display: flex;
    align-items: center;
    height: 75px;
    margin-top: 5px;
    padding-right: 10px;
    font-size: 15px;
    background-color: #101525;
    border-radius: 3px;
    cursor: pointer;
}

.video-entry.active {
    background-color: var(--hover-blue);
}

.video-entry-thumb {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.video-entry-thumb img {
    width: 120px;
    height: 73px;
}

.video-entry-logos {
    display: flex;
    justify-content: center;
    width: 45px;
    height: 95%;
    margin-right: 10px;
    overflow: hidden;
}

.video-entry-logos img {
    height: 75px;
    transform: rotate(18deg) translateX(-20%) translateY(12%);
    filter: brightness(40%);
}

.shl .video-entry-logos img {
    height: 75px;
    transform: rotate(18deg) translateX(-20%) translateY(12%);
    filter: brightness(40%);
}

.metadata-and-details {
    padding-top: 3px;
    width: 80%;
}

.video-without-video-icon {
    width: 150px;
    font-size: 30px;
}

.video-entry-icons {
    display: flex;
    justify-content: center;
    font-size: 20px;
    width: 32px;
}

.video-entry-metadata {
    height: 30%;
    font-size: 12px;
}

.game-time {
    justify-content: center;
    width: 27px;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    background-color: var(--light-blue);
    color: black;
    border-radius: 2px;
}

.shl .game-time {
    width: 45px;
    font-size: 11px;
}

.video-entry-details {
    font-size: 13px;
}

.video-duration {
    position: absolute;
    bottom: 1px;
    left: 0;
    font-size: .8em;
    background-color: rgba(0, 0, 0, 0.555);
    padding: 2px 3px;
    text-shadow: #000 0 0 2px, #000 0 0 2px, #000 0 0 2px, #000 0 0 2px;
}

.video-entry-metadata > i {
    margin-right: 9px;
    color: #bbbbbb;
}

.video-checkbox-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.video-checkbox-action div{
    height: 80%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.video-checked-box,
.video-unchecked-box {
    font-size: 30px;
    padding: 7px;
}

.video-checked-box {
    color: var(--dark-yellow);
}

.no-checkbox-action {
    width: 10px;
}

.video-entry-info-title {
    height: 100%;
    width: 100%;
    padding: 5px 0;
}

.entry-date-and-game {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 2px;
    height: 20px;
    font-size: 11px;
    font-style: italic;
    color: var(--grey);
}

.is-event .entry-date-and-game {
    justify-content: flex-end;
}

.entry-date-and-game div {
    display: flex;
    align-items: center;
    gap: 2px;
}

.entry-date-game-icon {
    font-size: 17px;
    color: var(--light-blue);
}

.video-entry-top-bottom {
    width: 100%;
    height: 100%;
    padding: 5px 0;
}

.video-entry-top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    /* border: 1px solid red; */
}

.video-entry-bottom {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    /* border: 1px solid green; */
}

.video-entry-date,
.video-entry-game {
    min-width: 90px;
}

.shl .video-entry-game {
    width: 100px;
}

.asset-tag-video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto 0;
    height: fit-content;
    cursor: pointer;
}

/* TAGGED EVENT */
a.video-entry.tagged-event {
    height: 60px;
    text-decoration: none;
    color: white;
}

.tagged-event .video-entry-thumb img {
    width: unset;
    height: calc(100% - 2px);
    margin-left: 1px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.tagged-event .video-entry-logos img,
.tagged-event.shl .video-entry-logos img {
    height: 90%;
}

.tagged-event .video-entry-top {
    justify-content: unset;
}

.tagged-event .video-entry-details {
    font-size: 12px;
}

/* MOBILE */
.video-entry-mobile {
    display: flex;
    height: 88px;
    width: 100%;
    margin-top: 5px;
    padding: 3px;
    background-color: #101525;
    border-radius: 3px;
    cursor: pointer;
}

.video-entry-mobile-thumb {
    position: relative;
    height: 100%;
    margin-right: 10px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 3px;
}

.video-entry-mobile-thumb img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    border-radius: 3px;
}

.video-entry-mobile-info {
    position: relative;
    padding: 5px 0;
    padding-right: 2px;
}

.video-entry-mobile-details {
    z-index: 1;
}

.video-entry-mobile-title {
    display: flex;
    align-items: center;
    height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 11px;
    margin-bottom: 7px;
}

.video-entry-mobile-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50%;
    gap: 5px;
    /* border: 1px solid green; */
}

.video-entry-mobile-event {
    display: flex;
    align-items: center;
    gap: 7px;
    height: 50%;
}

.video-entry-mobile-date-match {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--grey)
}

.video-entry-mobile-date {
    display: flex;
    align-items: flex-end;
    gap: 3px;
    font-size: 10px;
}

.video-entry-mobile-date svg {
    font-size: 14px;
    color: var(--light-blue);
}

.video-entry-mobile-time {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
}

.video-entry-mobile-logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.video-entry-mobile-logo img {
    height: 22px;
}

.video-duration.mobile {
    left: 1px;
}

.video-entry-mobile-checkbox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 0;
    min-width: 0;
    opacity: 0;
    transition: all .13s linear;
}

.video-entry-mobile-checkbox .video-checked-box,
.video-entry-mobile-checkbox .video-unchecked-box {
    font-size: 22px;
    padding: 2px;
}

.video-entry-mobile-checkbox.show {
    min-width: 25px;
    opacity: 1;
}

/* HOVER */
.video-entry:hover {
    background-color: var(--hover-blue);
}
.video-checkbox-action div:hover > .video-unchecked-box {
    color: var(--dark-yellow);
}