.upload-page-cont {
    width: 100%;
    padding-bottom: 10px;
    height: fit-content;
}

.upload-dnd-single {
    width: 100%;
    height: 100%;
    padding: 5px;
    padding-right: 10px;
}

.upload-video-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 15px;
}

.upload-video-player {
    display: flex;
    align-items: center;
    width: 60%;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 3px;
}

.upload-video-cont video {
    width: 100%;
}

.upload-dnd-and-meta {
    display: flex;
    flex-direction: column;
    width: calc(40% - 15px);
    min-height: 100%;
}

.upload-page-dnd-cont {
    width: 100%;
    height: 50%;
    flex-grow: 1;
}

.input-container input::placeholder,
.input-container textarea::placeholder {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.5);
}

.upload-title-input {
    margin-bottom: 5px;
}

.chosen-channel-list {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 10px;
    gap: 10px;
}

.chosen-channel-single {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 7px 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    background-color: #101525;
    gap: 10px;
}

.selected-channels-cont {
    width: 100%;
}

.upload-dropdown-cont {
    position: relative;
    width: 100%;
    font-size: 14px;
}

.upload-dropdown-title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 27px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--dark-yellow);
}

.upload-dropdown-list {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: black;
}

.upload-channel-search-cont {
    display: flex;
    padding: 10px;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--dark-yellow);
}

.upload-channel-search {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--dark-yellow);
}

.upload-select-minimum {
    color: rgba(255, 255, 255, 0.5);
}

.upload-channel-search-cont input[type="text"] {
    width: 150px;
    padding: 3px 7px;
    margin-left: 5px;
    border: none;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 3px;
    font-family: inherit;
    font-size: 14px;
    color: white;
}

.upload-channel-search-cont input[type="text"]:focus-visible {
    outline: none;
}

.upload-channel-list {
    max-height: 420px;
    overflow-y: scroll;
}

.channel-single {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 10px 12px;
    cursor: pointer;
}

.channel-single.active {
    background: var(--light-blue);
}

.no-channels-available {
    display: flex;
    align-items: center;
    height: 40px;
    color: rgba(255, 255, 255, 0.5);
}   

.remove-channel-icon {
    margin-bottom: -1px;
    cursor: pointer;
}

.input-container.match-associate {
    padding: 0;
}

.publish-period-options {
    width: 100%;
}

.publish-later {
    margin-right: 45px;
}

.check-box-icon {
    font-size: 18px;
}

.option-box-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-icon {
    margin-bottom: -2px;
}

.time-of-recording-cont {
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 30px;
    padding-bottom: 5px;
    gap: 10px;
    border-bottom: 1px solid var(--dark-yellow);
    cursor: pointer;
}

.time-of-recording-picker {
    display: flex;
    align-items: center;
}

.time-of-recording-picker input[type="text"] {
    width: 132px;
    padding: 0;
    font-size: 14px;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    caret-color: transparent;
}

.time-of-recording-picker input[type="text"]:focus-visible {
    outline: none;
}

.time-of-recording-cont svg {
    font-size: 17px;
    cursor: pointer;
}

.upload-file-metadata-cont {
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
    padding: 0 5px;
}

.upload-page-cont .padding-bottom {
    padding-bottom: 15px;
}

.upload-file-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 7px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 13px;
}

.upload-info-title {
    color: var(--grey);
}

.upload-info-value {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* HOVER */
.channel-single:hover {
    background: var(--light-blue);
}

.remove-channel-icon:hover,
.upload-dropdown-title:hover,
.remove-interviewee-icon:hover {
    color: var(--dark-yellow);
}

.upload-dropdown-title:hover {
    border-color: var(--dark-yellow);
}

.cancel-upload-btn:hover {
    background-color: var(--button-red);
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
    .upload-video-cont {
        flex-direction: column;
        padding: 7px 0;
    }
    .upload-video-player {
        width: 100%;
    }
    .upload-dnd-and-meta {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        gap: 7px;
    }
    .upload-page-dnd-cont {
        height: auto;
    }
    .upload-file-metadata-cont {
        width: 50%;
        margin-top: 0;
    }
    .upload-page-cont form {
        padding: 0;
    }
    .upload-dropdown-cont,
    .upload-dropdown-title {
        font-size: 14px;
    }
    .option-box-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .input-container input::placeholder,
    .input-container textarea::placeholder {
        font-size: 12px;
    }
    .time-of-recording-cont {
        position: relative;
        height: 27px;
    }
    .time-of-recording-picker {
        top: 4px;
    }
    .time-of-recording-picker input[type="text"] {
        font-size: 14px;
    }
    .time-of-recording-cont svg {
        font-size: 17px;
    }
    .time-of-recording-cont button{
        position: absolute;
        right: 0;
    }
}

@media screen and (max-width: 578px) {
    .upload-dnd-and-meta {
        flex-direction: column-reverse;
        gap: 7px;
    }
    .upload-file-metadata-cont {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .upload-page-cont .input-container {
        margin-bottom: 30px;
    }
    .chosen-channel-list {
        margin-bottom: 5px;
    }
    .chosen-channel-single {
        font-size: 12px;
        padding: 7px;
    }
    .upload-channel-search-cont input[type="text"] {
        font-size: 12px;
    }
    .time-of-recording-picker input[type="text"] {
        width: 110px;
        font-size: 12px;
    }
}