.dropdown {
    position: relative;
    font-size: 13px;
}
button[disabled].dropdown-btn {
    font-style: italic;
}
span.dropdown-arrow {
    margin-top: 3px;
}
ul.centered li {
    text-align: center;
}
ul.dropdown-content {
    width: auto;
    list-style: none;
    margin: 0;
    padding-inline: 0;
    display: none;
    position: absolute;
    top: 100%;
    background-color: var(--bg-color);
    max-width: 275px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 75vh;
    min-width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    user-select: none;
    z-index: 1;
}
.dropdown-content li {
    padding: 8px 8px;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    cursor: pointer;
}
.dropdown.open .dropdown-content {
    display: block;
}
.dropdown-content li.active {
    color: var(--dark-yellow);
}
.dropdown-btn:focus,
.dropdown-btn:hover,
.dropdown.open .dropdown-btn,
.dropdown-content li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* MOBILE */
.mobile-dropdown-cont {
    display: flex;
    align-items: center;
}

.filter-single-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    padding: 0 5px;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.filter-single-mobile svg {
    font-size: 17px;
}

.filter-single-and-selected {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.filter-title-mobile {
    color: var(--dark-yellow);
}

.filter-selected-mobile {
    font-size: 15px;
    font-weight: 500;
}

.filter-selected-mobile.disabled {
    color: rgba(255, 255, 255, 0.5);
}

.filter-mobile-dropdown-list {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 10px;
    background-color: #101525;
    transform: translateX(-100%);
    transition: transform .1s linear;
    overflow: auto;
}

.filter-mobile-dropdown-list.open {
    transform: translateX(0%);
}

.filter-mobile-dropdown-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.filter-mobile-dropdown-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
}

.filter-mobile-back {
    margin: 0 0;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    font-size: 15px;
    cursor: pointer;
}