.navigation li.nav-link {
    margin-top: -1px;
}
li.nav-link > * {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: white;
    cursor: pointer;
}
li.nav-link > .small {
    padding: 10px;
    margin-right: 0;
} 
li.nav-link > .active {
    color: var(--dark-yellow);
}

@media screen and (max-width: 1270px) {
    li.nav-link > .small {
        padding: 0;
        padding-left: 3px;
    }
}

@media screen and (max-width: 960px) {
    .navigation li.nav-link {
        width: 100%;
    }
}